Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.btnExampleTitle = "CLICK ME";
exports.tabsNames=["Buying Requests","Products","Orders","Offers","Wish Requests"]

exports.subtabsMapping = {
  "Buying Requests":[],
  Products:["All Products","Hidden Products"],
  Orders: ['Pending Orders', 'Accepted Orders', 'Rejected Orders'],
  Offers: ['Pending Offers', 'Accepted Offers', 'Rejected Offers'],
  'Wish Requests': ['Pending Requests', 'Accepted Requests', 'Rejected Requests'],
};
exports.filterDateLabels={
  "Wish Requests":"Request Date",
  "Offers":"Offer Date",
  "Orders":"Order Date",
  "Buying Requests":"Listed Date",

}
exports.priceRangesLabels={
  "Offers":"Listed Range",
  "Orders":"Listed Price",
  "Buying Requests":"Listed Price",
  "Products":"Listed Range"
}
// Customizable Area End