import React from "react";

// Customizable Area Start
import { createTheme, styled } from "@mui/material/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import { BrandList } from "../../../components/src/BrandList.web";
import { Typography,InputAdornment,TextField ,Button,Grid,Box} from "@mui/material";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import ImageCarousel from "../../../components/src/SliderComponent";
import LandingPageWebController, { Props } from "./LandingPageWebController.web";
import SearchIcon from "@mui/icons-material/Search";
import {  rightArrow,watchImage } from "./assets";
import CategoriesWeb from "../../categoriessubcategories/src/Categories.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import MakeAWish from "../../requestmanagement/src/MakeAWish.web";

// Styled components using MUI's `styled` method
const LandingPageContainer = styled("div")({
  position: "relative",
  backgroundColor: "#EDE6DC",
  overflowX: "hidden",
});

const ContentContainer = styled("div")({
  width: "100%",
  boxSizing: "border-box",
});

const GradientText = styled("div")({
  fontSize: "94px", 
  fontWeight: "500",
  background: "linear-gradient(to bottom, rgba(237, 230, 220, 1) 50%, rgba(83, 80, 63, 1) 50%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
});

const StyledTypography = styled(Typography)({
  marginTop: "30px",
  fontFamily: "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
  fontSize: 30,
  fontWeight: "500",
  textAlign: "center",
  color: "#53503F",
  lineHeight: "36.27px",
  textTransform: "uppercase",
});

const BrandSection = styled("div")({
  position: "relative",
  zIndex: 99,
});
const GridContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  // justifyContent:"center",
  gap: "4.5rem",
  overflowX: "auto",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    height: "3px",
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(83, 80, 63, 1)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
    borderRadius: "4px",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(83, 80, 63, 1) transparent",
}));
const MyTextField = styled(TextField)(({ theme }) => ({
  width: "480px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "25px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  margin:"25px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));
const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  paddingLeft:"-10px",
  backgroundColor: "rgba(237, 230, 220, 0.5)",
});
const StyledSearchIcon = styled(SearchIcon)({
  width: "17.5px",
  height: "17.5px",
});
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class LandingPageWeb extends LandingPageWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <LandingPageContainer data-test-id="landingPageContainer">
        <AppHeader 
        navigation={this.props.navigation}
        count={this.state.cartLength}
        closeLoginModal={this.closeLoginModal}
        openLoginModal={this.state.openLoginModal}
        allFilter={this.state.allFilter} 
        handleCloseAllFilter={this.handleCloseAllFilter}
        categoryPageVisibility={this.handleCategoryVisibility}
        handleApplyButton={this.handleApplyButton}
        categoryId={this.state.categoryId}
        genderForFilter={this.state.gender}
        />
        <ContentContainer>
          <FullWidthTabComponent
            categoriesOnHoverList={this.state.categoriesOnHoverList}
            getCategoriesOnHover={this.getCategoriesOnHover}
            categoriesList={this.state.categoriesArray}
            handleCategories={this.handleCategoryData}
            handleMoveToHome={this.handleCategoryVisibility}
          />
          <div className="searchBar" style={{ textAlign: "center"}}>
          <AdvancedSearch navigation={this.props.navigation} id="" handleShowCategory={this.handleShowCategory} getCategoryData={this.handleCategoryDataSearch} />
          </div>
          {this.state.showCategoryPage === true ?
          <CategoriesWeb handleShowCategory={this.handleShowCategory} navigation={this.props.navigation} id={""} filterData={this.state.filterData} categoryData={this.state.categoryData} passDataToFilter={this.handlePassData} allFilter={this.handleAllFilter} categoryId={this.state.categoryId} categoryName={this.state.categoryName}/> :
          <>
          <div style={{ position: "relative" }}>
            <div
              style={{
                width: "100%",
                height: "600px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                style={{ 
                  width: "100%",
                  objectFit: "cover",
                }}
                src={this.state.landingPagesData?.landing_pages?.[0]?.image}
                alt="image"
                height={600}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "185px",
                  background:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                }}
              />
            </div>
            <div
              ref={this.assignRef(0)}
              style={{
                background: "rgba(255, 255, 255, 1)",
                height: "215px",
                boxSizing: "border-box",
                width: "100%",
                scrollBehavior: "smooth",
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 15,
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                  paddingRight: "30px",
                  alignItems: "center",
                  paddingTop: "49px",
                }}
              >
                <div
                  style={{
                    cursor: this.state.scrollStates[0]?.canScrollLeft
                      ? "pointer"
                      : "not-allowed",
                    opacity: this.state.scrollStates[0]?.canScrollLeft
                      ? 1
                      : 0.5,
                  }}
                  data-test-id="scrollleftId"
                  onClick={() => this.scrollLeft(0)}
                >
                  <img
                    style={{
                      transform: "scaleX(-1)",
                    }}
                    src={rightArrow}
                    alt="arrow"
                    width={46}
                    height={12}
                  />
                </div>
                <div style={{cursor:"pointer"}}>
                <img
                  style={{
                    cursor: this.state.scrollStates[0]?.canScrollRight
                      ? "pointer"
                      : "not-allowed",
                    opacity: this.state.scrollStates[0]?.canScrollRight
                      ? 1
                      : 0.5,
                  }}
                  data-test-id="scrollRightId"
                  onClick={() => this.scrollRight(0)}
                  src={rightArrow}
                  alt="arrow"
                  width={46}
                  height={12}
                />
                </div>
              </div>
              <div
                className="brandContainer"
                style={{
                  display: "flex",
                  width: "300%",
                  scrollBehavior: "smooth",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  scrollbarWidth: "none",
                  paddingTop: "64px",
                }}
              >
                {this.state.landingPagesData.brand_images?.map(
                  (ele: {image_url:string, id: number}) => (
                    <div
                      className="imgContainer"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "300px",
                      }}
                    >
                      <img src={ele.image_url} style={{cursor:"pointer"}} data-testid={`brandImageTestId`} onClick={() => this.handleBrandNavigation(ele.id)}  alt="" height={100} width={150} />
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              className="bigText"
              style={{
                position: "relative",
                marginTop: "-390px",
                display: "flex",
                flexDirection: "column",
                gap: "50px",
              }}
            >
              <Typography
                sx={{
                  fontFamily:
                    "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
                  fontSize: "45px",
                  fontWeight: 500,
                  lineHeight: "67.5px",
                  letterSpacing: "-0.75px",
                  textAlign: "center",
                  color: "rgba(237, 230, 220, 1)",
                }}
              >
                {this.state.landingPagesData?.landing_pages?.[0]?.heading_text}
              </Typography>
              <GradientText
                sx={{
                  fontFamily:
                    "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
                  lineHeight: "113px",
                  letterSpacing: "-0.75px",
                  textAlign: "center",
                  // color:"rgba(83, 80, 63, 1)"
                }}
              >
                {"TRÉSOR"}
              </GradientText>
            </div>
          </div>
          <Grid container md={12} sm={12} lg={12} sx={{ marginTop: "250px" }}>
            <Grid
              md={12}
              lg={12}
              sm={12}
              spacing={2}
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "25px",
              }}
            >
              <Grid md={4} sm={3} lg={4}></Grid>
              <Grid md={4} sm={3} lg={4}>
                <Typography
                  sx={{
                    fontFamily:
                      "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
                    fontSize: "35px",
                    fontWeight: 500,
                    lineHeight: "42.31px",
                    textAlign: "center",
                    color: "rgba(83, 80, 63, 1)",
                  }}
                >
                  Shop by Categories
                </Typography>
              </Grid>
              <Grid
                md={4}
                sm={3}
                lg={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "flex-end",
                    paddingRight: "30px",
                    alignItems: "center",
                  }}
                >
                  <div
                  data-test-id="scrollLeft1"
                    onClick={() => this.scrollLeft(1)}
                    style={{
                      cursor: this.state.scrollStates[1]?.canScrollLeft
                        ? "pointer"
                        : "not-allowed",
                      opacity: this.state.scrollStates[1]?.canScrollLeft
                        ? 1
                        : 0.5,
                    }}
                  >
                    <div style={{cursor:"pointer"}}>
                    <img
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      src={rightArrow}
                      alt="arrow"
                      width={46}
                      height={12}
                    />
                    </div>
                  </div>
                  <div
                  data-test-id="scrollRight1"
                    style={{
                      cursor: this.state.scrollStates?.[1]?.canScrollRight
                        ? "pointer"
                        : "not-allowed",
                      opacity: this.state.scrollStates?.[1]?.canScrollRight
                        ? 1
                        : 0.5,
                    }}
                    onClick={() => this.scrollRight(1)}
                  >
                    <img src={rightArrow} alt="arrow" width={46} height={12} />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              sx={{
                flexFlow: "nowrap",
                marginLeft: "0px",
                marginTop: "0px",
                paddingLeft: "40px",
                paddingRight: "40px",
                columnGap: "15px",
                rowGap: "15px",
                overflowX: "auto",
              }}
              container
              md={12}
              sm={12}
              lg={12}
              spacing={2}
            >
              <GridContainer ref={this.assignRef(1)}>
                {this.state.landingPagesData?.categories?.map(
                  (categorie: any,index:number) => (
                    <Grid
                      item
                      sx={{
                        width:"100%",
                        maxWidth: "441px",
                        flexShrink: "0",
                        height: "540px",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "440px",
                          borderRadius: "4px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={categorie?.logo || ""}
                          height={440}
                          style={{ width: "100%", borderRadius: "4px",objectFit:"cover" }}
                          alt="gg"
                        />

                        {/* SHOP NOW overlay */}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            height: "77px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                            opacity: 0, // Hidden by default
                            transition: "opacity 0.3s ease", // Smooth transition
                            color: "#fff",
                            fontSize: "24px",
                            fontWeight: 500,
                            cursor:"pointer",
                            fontFamily: "'Big-Caslon-Medium',serif !important",
                            "&:hover": {
                              opacity: 1, // Show on hover
                            },
                          }}
                          data-test-id={`hover${index}`}
                          onClick={()=>{
                            this.handleCategoryData(categorie.name,categorie.id)
                          }}
                        >
                          SHOP NOW
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          gap: "15px",
                          flexDirection: "column",
                          marginTop: "30px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontFamily: "'Big-Caslon-Medium',serif !important",
                            fontSize: "35px",
                            color: "rgba(83, 80, 63, 1)",
                            lineHeight: "42.31px",
                            fontWeight: 500,
                          }}
                        >
                          {categorie?.name}
                        </Typography>
                      </Box>
                    </Grid>
                  )
                )}
              </GridContainer>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              width: "100%",
              height: "600px",
              background: "rgba(239, 238, 233, 1)",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "65px",
            }}
          >
            <div className="image" style={{ width: "50%", height: "100%" }}>
              <img
                src={this.state.landingPagesData?.landing_pages?.[1]?.image}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                background: "rgba(239, 238, 233, 1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <span
                className="bigCaslont"
                  style={{
                    maxWidth: "499px",
                    color: "rgba(83, 80, 63, 1)",
                    // fontFamily: "'Big-Caslon-Medium',serif",
                    fontSize: "65px",
                    fontWeight: 500,
                    lineHeight: "87.75px",
                    letterSpacing: "-0.75px",
                    textAlign: "center",

                    wordBreak: "break-word",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {
                    this.state.landingPagesData?.landing_pages?.[1]
                      ?.heading_text
                  }
                </span>
                <hr
                  style={{
                    backgroundColor: "rgba(83, 80, 63, 1)",
                    height: "2px",
                    width: "240px",
                    border:"none"
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontSize: "25px",
                    fontWeight: 400,
                    lineHeight: "29px",
                    textAlign: "center",
                    color: "rgba(83, 80, 63, 1)",
                    wordBreak: "break-word",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    maxWidth: "499px",
                  }}
                >
                  {this.state.landingPagesData?.landing_pages?.[1]?.title}
                </Typography>

                <Button
                  sx={{
                    width: "200px",
                    height: "56px",
                    borderRadius: "4px",
                    background: "rgba(83, 80, 63, 1)",
                    fontFamily: "'Big-Caslon-Medium',serif !important",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "white",
                    marginTop: "50px",
                    ":hover": { background: "rgba(83, 80, 63, 1)" },
                  }}
                  onClick={this.handleMakeWishModal}
                >
                  Make a wish
                </Button>
              </div>
            </div>
          </div>

          <BrandSection data-test-id="brandSection">
            <BrandList
            navigateToDetailedProductView={this.navigateToDetailedProductView}
              index={0}
              brandsList={this.state.landingPagesData?.new_exclusive}
              categoryLable={"New & Exclusive"}
              shwoBrandComponent={false}
              ViewAllLink={"/"}
              onFavoriteClick={this.handleAddToFavorite}

            />
            <BrandList
             navigateToDetailedProductView={this.navigateToDetailedProductView}
             onFavoriteClick={this.handleAddToFavorite}
              index={1}
              brandsList={this.state.landingPagesData?.featured_product}
              categoryLable={"Featured Products"}
              shwoBrandComponent={false}
              ViewAllLink={"/"}
              brandNameDesc={
                "Explore the latest collections from our most loved in house labels"
              }
            />
          </BrandSection>

          <div
            className="watchDiv"
            style={{
              height: "600px",
              width: "100%",
              display: "flex",
              margin: "70px 0px",
              position: "relative",
            }}
          >
            <div
              className="imageDiv"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                height: "100%",
                background: "rgba(83, 80, 63, 0.92)",
              }}
            >
              <div
                style={{
                  gap: "17px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={watchImage}
                  alt="watchImage"
                  style={{ borderRadius: "4px" }}
                  height={366}
                  width={343}
                />
                <Typography
                  sx={{
                    fontFamily: "'Big-Caslon-Medium',serif !important",
                    fontSize: "35px",
                    fontWeight: 500,
                    lineHeight: "42px",
                    letterSpacing: "0.25px",
                    textAlign: "center",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {
                    this.state.landingPagesData?.landing_pages?.[2]
                      ?.heading_text
                  }
                </Typography>
                <Button
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    color: "rgba(237, 230, 220, 1)",
                    width: "160px",
                    height: "44px",
                    borderRadius: "4px",
                    border: "1px solid rgba(237, 230, 220, 1)",
                    ":hover": {
                      background: "transparent",
                    },
                  }}
                >
                  Explore all
                </Button>
              </div>
            </div>
            <div className="rightImg" style={{ width: "50%", height: "100%" }}>
              <img
                src={this.state.landingPagesData?.landing_pages?.[2]?.image}
                alt="watch"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "52.5%",
                transform: "translate(-40%, -50%)",
                textAlign: "center",
                color: "white",
                padding: "10px 20px",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "'Big-Caslon-Medium',serif !important",
                  fontSize: "85px",
                  fontWeight: 500,
                  lineHeight: "1.2",
                }}
              >
                Trendy And  
                <br />
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "96px",
                  }}
                >
                  Perfect.
                </span>
              </Typography>
            </Box>
          </div>
          </>}
        </ContentContainer>
        <MakeAWish data-test-id="makeAWishTest" openModal={this.state.openMakeWishModal} handleCloseModal={this.handleMakeWishModal} navigation={this.props.navigation} id='makewish'/>
        <Footer data-test-id="footerTest" openLoginModal={this.openLoginModal}  handleCategoryData={this.handleCategoryData} categoriesList={this.state.landingPagesData?.categories} />
      </LandingPageContainer>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const webStyle = {};
// Customizable Area End