import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { SelectChangeEvent } from '@mui/material/Select';

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  categoryData: any,
  allFilter: any,
  navigation?:any,
  id:any,
  filterData: any,
  passDataToFilter: any,
  categoryId: any,
  categoryName: string;
  handleShowCategory?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  product: any;
  sortOption: string;
  filterBackendError: string;
  sortingByWomen: boolean;
  sortingByMen: boolean;
  filterOpen: boolean;
  productCount: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  productApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      product: [],
      sortOption: '',
      filterBackendError: '',
      sortingByWomen: false,
      sortingByMen: false,
      filterOpen: false,
      productCount: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.productApiCallId){
          this.handleProductApiCallId(responseJson)
        }
       
      }
    }
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body } = data
    const header = {
      'Content-Type': contentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async componentDidMount() {
    const { categoryId } = this.props;
    this.handleProduct(categoryId)
  }
  
  async componentDidUpdate(prevProps: any) {
    const { filterData, categoryId } = this.props;

    if (prevProps.filterData !== filterData || prevProps.categoryId !== categoryId) {
      const filterCategoryId = filterData?.categoryId || categoryId;
  
      if (filterCategoryId !== prevProps.categoryId || filterData !== prevProps.filterData) {
        await this.handleProduct({ ...filterData, categoryId: filterCategoryId });
      }
    }
    if (
      prevProps.categoryData !== this.props.categoryData &&
      this.props.categoryData.length > 0
    ) {
      this.updateDisplayedProducts();
    }
  }
  updateDisplayedProducts() {
    const products = this.props.categoryData;
  this.setState({ product: products });
  }
  navigateToDetailedProductView=(productId:string)=>{
    this.props.navigation.navigate("ProductDescription",{productId:productId,path:{productId:productId}})
  }

  handleProductApiCallId = (responseJson: any) => {
    if(responseJson && responseJson.data){
      this.setState({
        product: responseJson.data,
        productCount: responseJson.meta.total_count
      })
    }
    if(responseJson && responseJson.errors){
      this.setState({product: [], filterBackendError: responseJson.errors[0].message})
    }
  }

  handleProduct = async (data: any) => {
    const { sortingByWomen, sortingByMen } = this.state;
    const queryParamsObj: any = { category_id: this.props.categoryId };
    const filterConditions = [
      { key: 'sorted_by_women', condition: data.sortByWomen || sortingByWomen, value: 'true' },
      { key: 'sorted_by_men', condition: data.sortByMen || sortingByMen, value: 'true' },
      { key: 'sort_by', condition: data.sortByProductionYear, value: 'production_year' },
      { key: 'sort_by', condition: data.sortByHighToLow, value: 'price_high_to_low' },
      { key: 'sort_by', condition: data.sortByLowToHigh, value: 'price_low_to_high' },
      { key: 'filter_by_price_ranges', condition: data.priceRange?.length > 0, value: data.priceRange?.join('..') },
      { key: 'filter_by_brand_ids', condition: data.brand && data.brand !== "", value: data.brand },
      { key: 'filter_by_subcategory_ids', condition: data.subCategory?.length > 0, value: data.subCategory?.join(',') },
      { key: 'filter_by_product_year_production', condition: data.productionYear && data.productionYear !== "", value: data.productionYear },
      { key: 'sort_by', condition: data.datePosted && data.datePosted !== "", value: data.datePosted },
      { key: 'filter_by_color_ids', condition: data.color && data.color !== "", value: data.color },
      { key: 'filter_by_size_ids', condition: data.size && data.size !== "", value: data.size },
    ];
  
    filterConditions.forEach(({ key, condition, value }) => {
      if (condition) {
        queryParamsObj[key] = value;
      }
    });
  
    const queryParams = new URLSearchParams(queryParamsObj).toString();
  
    this.productApiCallId = await this.apiCall({
      method: configJSON.httpGetType,
      endPoint: `${configJSON.categoriesEndPoint}?${queryParams}`,
    });
  };  

  handleSortOption = (event: SelectChangeEvent) => {
    this.setState({sortOption: event.target.value})
  }
  
  handleWomenFilter = () => {
    this.setState({sortingByWomen: !this.state.sortingByWomen},() => {
      this.handleProduct(this.state.sortingByWomen)
      this.props.passDataToFilter(this.state.sortingByWomen ? 'sortByWomen' : '');
    })
  };


  handleMenFilter = () => {
    this.setState({sortingByMen: !this.state.sortingByMen}, () => {
      this.handleProduct(this.state.sortingByMen)
      this.props.passDataToFilter(this.state.sortingByMen ? 'sortByMen': '');
    })
  }

  handleRecentFilter = () => {
    this.handleProduct({ ...this.props.filterData, datePosted: 'recent' })
  }
  
  handleProductionYearFilter = () => {
    this.handleProduct({ ...this.props.filterData, sortByProductionYear: true })
  }

  handlePriceHighToLow = () => {
    this.handleProduct({ ...this.props.filterData, sortByHighToLow: true })
  }

  handlePriceLowToHigh = () => {
    this.handleProduct({ ...this.props.filterData, sortByLowToHigh: true })
  }
  // Customizable Area End
}